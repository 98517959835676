<script setup>
import { ref, watch } from "vue";

const props = defineProps({
    popout: {
        type: Boolean,
        default: false
    }
})

const modelData = ref([])
const searchQuery = ref()
const isLoading = ref(false)
const products = ref([])

const queryProducts = () => {
    isLoading.value = true
    axios.post(route('shop.available-products'), {
        searchQuery: searchQuery.value
    }).then(response => {
        isLoading.value = false
        products.value = response.data
    }).catch(response => {
        isLoading.value = false
        console.log(response)
    })
}

const clearProducts = () => {
    products.value = []
}

const isDisplayingSearch = ref(props.popout)
const isTransitioning = ref(false)

const emits = defineEmits(['update:popout'])
const togglePopout = () => {
    isDisplayingSearch.value = !isDisplayingSearch.value
    emits('update:popout', isDisplayingSearch.value)
}

const closeSearch = () => {
    isTransitioning.value = true
    setTimeout(() => {
        isDisplayingSearch.value = false
        emits('update:popout', false)
        clearProducts()
        searchQuery.value = ''
        isTransitioning.value = false
    }, 300)
}

watch(() => props.popout, (newValue) => {
    if (newValue) {
        isDisplayingSearch.value = true
    } else {
        closeSearch()
    }
})
</script>

<template>
    <transition name="fade">
        <v-autocomplete
            v-if="isDisplayingSearch"
            v-model="modelData"
            :items="products"
            :loading="isLoading"
            clearable
            hide-details
            @keydown="queryProducts"
            item-text="title"
            item-value="id"
            solo
            label="Search for a product"
            density="compact"
            max-width="400px"
            menu-icon="fa-regular fa-magnifying-glass"
            v-model:search="searchQuery"
            @click:clear="clearProducts"
        >
            <template v-slot:clear="{props}">
                <v-btn v-bind="props" density="compact" color="text" class="d-flex" icon="fa-thin fa-circle-xmark"/>
            </template>
            <template #label>
                <p class="d-flex text-accent mt-1">Search for a product...</p>
            </template>
            <template v-slot:append-inner>
                <v-btn
                    color="text"
                    class="close-search"
                    icon="fa-regular fa-circle-xmark"
                    density="compact"
                    @click="closeSearch"
                />
            </template>
            <template v-slot:item="{ item, props }">
                <a class="text-decoration-none" :href="route('categories.category.stock', [item.raw.category?.slug, item.raw.slug])">
                    <v-list-item v-bind="props">
                        <template v-slot:default>
                            <v-img
                                :src="item.raw.img_src"
                                width="60"
                                height="60"
                                max-width="60"
                                max-height="60"
                                class="mr-3"
                            />
                        </template>
                    </v-list-item>
                </a>
            </template>
            <template v-slot:no-data>
                <v-list-item v-if="!isDisplayingSearch">
                    <v-list-item-title>
                        Type to begin auto-filling
                    </v-list-item-title>
                </v-list-item>
            </template>
        </v-autocomplete>
    </transition>
    <v-btn
        v-if="!isDisplayingSearch && !isTransitioning"
        color="text"
        icon="fa-regular fa-magnifying-glass"
        class="d-flex align-center"
        @click="togglePopout"
    />
</template>

<style scoped>
:deep(i.fa-regular.fa-magnifying-glass.v-icon) {
    transform: rotate(0) !important;
}

:deep(.v-list-item__content) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: start;
}

:deep(.v-list-item-title) {
    display: flex;
    text-align: start !important;
    justify-content: start !important;
    color: rgb(var(--v-theme-accent));
    text-wrap: wrap !important;
}

:deep(.fa-magnifying-glass),
:deep(.fa-times-circle) {
    color: rgb(var(--v-theme-text));
}

:deep(.v-field--variant-filled.v-field--focused .v-field__overlay) {
    opacity: 0.1;
}

:deep(.v-field__input) {
    color: rgb(var(--v-theme-accent))
}

:deep(.v-input__append-inner) {
    margin-top: 7px !important;
}

:deep(.v-input__append-inner .v-icon) {
    cursor: pointer;
}

.close-search {
    position: absolute;
    top: -14px;
    right: -14px;
}
</style>
